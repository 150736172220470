<template>
  <div v-if="loaded" class="row">
    <div class="col-md-12 mb-4">
      <h1>
        What&lsquo;s your company name in {{ jurisdiction.state_province_region }}?
      </h1>
    </div>

    <div class="col-md-12 mb-2 full-name-div">
      <h5 class="full-name-text">
        {{ newFullCompanyName }}
      </h5>
    </div>

    <div class="form-container col-12 col-md-9 d-flex flex-nowrap justify-content-center align-items-center m-auto">
      <div
        id="company-name"
        class="d-flex flex-row justify-content-center"
        :class="showEntityTypeSuffixes ? 'col-md-6' : 'col-md-6'"
      >
        <b-form-input v-model="newCompanyName" placeholder="Company Name" />
      </div>

      <div
        v-if="showEntityTypeSuffixes"
        id="entity-suffix"
        class="col-md-6"
      >
        <b-form-select
          v-model="selectedEntitySuffix"
          class="w-100 pre-stageline-dropdown float-left"
          :options="entityTypeSuffixes"
        />
      </div>
    </div>

    <div class="button-nav-container col-12 col-md-9 m-auto">
      <previous-button-component />
      <button
        class="continue-btn btn btn-primary"
        aria-label="continue button"
        :disabled="!valid"
        @click="checkForSimilarName"
      >
        Continue
      </button>
    </div>
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isNotEmptyOrNull } from '@/common/modules/strings'

export default {
  name: 'CompanyName',
  components: {
    CtCenteredSpinner:       () => import('@/components/shared/CtCenteredSpinner'),
    PreviousButtonComponent: () => import('./PreviousButtonComponent'),
  },
  data() {
    return {
      company: null,
      selectedEntitySuffix: null,
      newCompanyName: null,
    }
  },

  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'entityType',
      'entityTypeSuffix',
      'companyName',
      'loaded',
    ]),
    ...mapGetters('entityTypes', ['entityTypeSuffixes']),

    showEntityTypeSuffixes() {
      return this.entityTypeSuffixes ? this.entityTypeSuffixes.length > 0 && this.loaded : false
    },
    newFullCompanyName() {
      return this.selectedEntitySuffix ? [this.newCompanyName, this.selectedEntitySuffix].join(' ')
                                       : this.newCompanyName
    },
    valid() {
      const companyNameIsValid = isNotEmptyOrNull(this.newCompanyName)
      const entityTypeIdIsValid = isNotEmptyOrNull(this.entityType.id)
      const companyHomeState = isNotEmptyOrNull(this.jurisdiction.id)
      const entityTypeSuffixIsValid = this.showEntityTypeSuffixes ? isNotEmptyOrNull(this.selectedEntitySuffix) : true
      return companyNameIsValid && entityTypeIdIsValid && companyHomeState && entityTypeSuffixIsValid
    },
  },

  async mounted() {
    this.setLoaded(false)
    await this.loadEntityTypeSuffixes()
    this.setSelectedCompanyNameAndSuffix()
    this.showLoading()
  },

  methods: {
    ...mapActions('entityTypes', ['getEntityTypeSuffixes']),
    ...mapActions('stagelineCreateCompany', [
      'setCompanyNameAndSuffix',
      'setCurrentSlide',
      'setLoaded',
      'ensureCompanyNameUniqueness',
      'createCompany',
      'setCreatingCompany',
    ]),
    ...mapActions('stageline', [
      'getAccountCompaniesSimple',
    ]),
    async loadEntityTypeSuffixes() {
      await this.getEntityTypeSuffixes({ entityTypeId: this.entityType.id, jurisdictionId: this.jurisdiction.id })
    },
    async checkForSimilarName() {
      this.setLoaded(false)
      await this.setCompanyNameAndSuffix(
        { companyName: this.newCompanyName, entityTypeSuffix: this.selectedEntitySuffix }
      )

      const result = await this.ensureCompanyNameUniqueness()
      if (result?.data) {
        if (!result?.data?.result?.success) {
          this.setCurrentSlide('SimilarCompanyName')
        } else {
          this.setCurrentSlide('ConfirmCompany')
        }
      } else {
        this.$bvToast.toast("An error has occurred while checking the company name's uniqueness", {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }

      this.setLoaded(true)
    },
    async createNewCompany() {
      const result = await this.createCompany()

      if (result.success) {
        await this.getAccountCompaniesSimple()
        this.setCreatingCompany(true)
        this.setCurrentSlide('CompanyCreated')
      } else {
        this.$bvToast.toast('An error has occurred adding the company to your account', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    setSelectedCompanyNameAndSuffix() {
      const validEntitySuffix = this.entityTypeSuffixes.some(s => s.text === this.entityTypeSuffix)
      this.selectedEntitySuffix = validEntitySuffix ? this.entityTypeSuffix : null
      this.newCompanyName = this.companyName
    },
    showLoading() {
      setTimeout(() => { this.setLoaded(true) }, 500)
    },
  },
}
</script>
<style lang="scss" scoped>

div#company-name,
div#entity-suffix {
  padding: 0.625em;
}

.full-name-div {
  height: 50px;
  color: #000864;
}
.full-name-text {
  margin-top: 0;
}

div.button-nav-container {
  width: 100%;
  margin-top: 1.25em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.5625em;
  margin-top: 3.125em!important;
  button.continue-btn {
    justify-self: flex-end;
    background: $ct-ui-primary;
    border-color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-dark;
      border-color: $ct-ui-primary;
    }
  }
}

@media only screen and (max-width: 660px) {
  div.form-container {
    flex-direction: column !important;

    div#company-name,
    div#entity-suffix {
      padding-bottom: 0.8em;
    }
  }
}
.previous-button {
  color: #2FB2AE;
}
</style>
